.homepage {
  position: relative;
  display: flex;
  background-image: url("../Images/background.png");
  background-size: cover;
  width: 100%;
  height: auto;
  
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.homepage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85); /* Dark overlay */
  z-index: 1;
  box-shadow: 0 0 8px 8px black inset;
}

.content {
  flex-grow: 1;
}