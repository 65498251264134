.hero {
    position: relative;
    z-index: 2;
    height: 60vh;
    display: flex;
    align-items: center;
    background-color: #2c394b;
    margin-top: 10px;
  }
  
  .hero-image {
    flex: 1; /* Takes up 50% of the width */
    max-width: 50%; /* Optional: Limits the image width to 50% */
  }

  .hero-image img {
    margin-left: 40px;
    width: 80%;
    height: auto; /* Ensures image maintains aspect ratio */
    border-radius: 10px;
  }
  
  .hero-text {
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.7rem;
    font-weight: 430;
    height: 100%;
    max-width: 50%; /* Optional: Limits the text section width to 50% */
  }
  
  .hero-h1 {
    color: white;
    margin-bottom: 20px;
  }
  
  .hero-p {
    color: white;
    font-size: small;
    justify-content: center;
    
  }
  
  .hero-p:first-of-type {
    margin-top: 10px;
  }
  
  .hero-p:last-of-type {
    bottom: 20px; /* Keep the second paragraph at the bottom */
  }